import React from 'react'
import { useTranslation } from 'react-i18next';

function SubscribArea() {
  const { t } = useTranslation();
  return (
    <>
      <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div className="col-lg-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-cnt">
                  <span className='mb-2'>{t("Size Ulaşalım")}</span>
                  <h3>{t("Gelİşmelerden Haberdar Olmak İçİn")}</h3>
                  <strong>{t("Abone Olun")}</strong>
                </div>
              </div>
              <div className="col-lg-6 wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-form">
                  <form onScroll={(e) => e.preventDefault()} action="#" method="post">
                    <input type="email" name="email" placeholder={t("E-posta")} />
                    <input type="submit" placeholder='' defaultValue="connect" value={t("Gönder")} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SubscribArea
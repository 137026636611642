import React from 'react'
import { useTranslation } from 'react-i18next';

function WhyChooseUs() {
    const { t } = useTranslation();
    return (
        <>
            <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="why-choose-left">
                                <div className="choose-banner1">
                                    <img src={process.env.PUBLIC_URL + "/img/why-1.jpg"} alt="why-choose images" />
                                </div>
                                <div className="choose-banner2">
                                    <img src={process.env.PUBLIC_URL + "/img/why-2.jpg"} alt="why-choose images" />
                                    <img src={process.env.PUBLIC_URL + "/img/why-3.jpg"} alt="why-choose images" />
                                </div>
                                {/* <div className="years">
                                    <h5>20+</h5>
                                    <span>Years</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-choose-right">
                                <div className="sec-title layout2 mb-4">
                                    <span>{t("Neden Biz ?")}</span>
                                    <h2>{t("İşletmenizin Başarısı İçin Doğru Tercih")}</h2>
                                </div>
                                <p className='mb-5'>{t("Medici Consultancy şirketi, en son teknolojik araçları ve sektörel bilgi birikimini kullanarak müşterilerine danışmanlık hizmetleri sunan bir şirkettir. Müşteri memnuniyeti odaklı yaklaşımıyla her müşterinin farklı ihtiyaçlarına uygun çözümler sunar ve işletmelerin büyümesine ve gelişmesine yardımcı olur. Medici Consultancy, güvenilir bir iş ortağı olarak kaliteli hizmetleri ve müşteri odaklı yaklaşımı ile öne çıkar.")}</p>
                                <div className="counter-boxes">
                                    <div className="count-box">
                                        <span className="counter">43</span><sup></sup>
                                        <h5>{t("Tamamlanmış Proje")}</h5>
                                    </div>
                                    <div className="count-box">
                                        <span className="counter">36</span><sup></sup>
                                        <h5>{t("Memnun Müşteri")}</h5>
                                    </div>
                                    <div className="count-box">
                                        <span className="counter">16</span><sup></sup>
                                        <h5>{t("BT Uzmanı")}</h5>
                                    </div>
                                </div>
                                <div className="buttons-group">
                                    <span>{t("Kesintisiz Destek")}</span>
                                    <span>{t("Benzersiz Tasarım")}</span>
                                    <span>{t("SEO Dostu Geliştirme")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs
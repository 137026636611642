import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";
import { useLocation } from 'react-router-dom'
import ServiceList from "../../../ServiceList"
import { useTranslation } from 'react-i18next';

function ServiceDetailsWrap({ item }) {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

    let paragraphVal = item.paragraph.map((e) => {
        return (
            (Object.values(e))
        )
    })

    const { t } = useTranslation();

    return (
        <>
            <div className="service-details sec-mar-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <img src={item.detailImg} alt="images service" />
                                <h3>
                                    {t(item.title)}
                                </h3>
                                {
                                    paragraphVal.map((e, index) => {
                                        if (e.length > 0) {
                                            return (
                                                <p key={index}>{t(e)}</p>
                                            )
                                        }
                                        else {
                                            return;
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-widget">
                                <h4>{t("Tüm Hİzmetler")}</h4>
                                <ul className="category">
                                    {
                                        ServiceList.map((item) => {
                                            if (item.id) {
                                                return (
                                                    <li key={item.id}>
                                                        <Link to={{
                                                            pathname: '/service/' + `${item.link}`, state: { item }
                                                        }}
                                                            onClick={scrollTop}>
                                                            {t(item.title)}
                                                            <i className="bi bi-arrow-right" />
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="sidebar-banner">
                                <img src={process.env.PUBLIC_URL + '/img/widget-banner-bg.jpg'} alt="Banner images" />
                                <div className="banner-inner">
                                    <h3>
                                        {t("Bİlgİ Almak İçİn")} <span>{t("Bİze Ulaşın")}</span>
                                        <img
                                            className="angle"
                                            src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                                            alt=""
                                        />
                                    </h3>
                                    <a href="mailto:support@mediciconsultancy.com">support@mediciconsultancy.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <ServiceDetailsItems /> */}
                </div>
            </div>
        </>
    );
}

export default ServiceDetailsWrap;

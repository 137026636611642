import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ContactWrapper from "./ContactWrapper";
import AnimateLine from "../../common/AnimateLine";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Medici Consultancy | İletişim")}</title>
      </Helmet>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb pageTitle={t("İletişim")} pageName={t("İletişim")} />
        <ContactWrapper />
        <SubscribArea />
      </div>
    </>
  );
}

export default Contact;

import React from "react";
import { Link } from "react-router-dom";
import ServiceList from "../../../ServiceList"
import { useTranslation } from 'react-i18next';
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Autoplay,
    Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    const { t } = useTranslation();

    const serviceSlider = {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    }
    return (
        <>
            <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
                <div className="container">
                    <div
                        className="title-wrap wow animate fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                    >
                        <div className={`${"sec-title"} ${props.white}`}>
                            <span>{t("Çözümlerimiz")}</span>
                            <h2>{t("Sunduğumuz Hizmetler")}</h2>
                        </div>
                    </div>
                    <Swiper {...serviceSlider} className="swiper services-slider">
                        <div className="swiper-wrapper">
                            {
                                ServiceList.map((item) => {
                                    return (
                                        <SwiperSlide
                                            key={item.id}
                                            className="swiper-slide wow animate fadeInUp"
                                            data-wow-delay="200ms"
                                            data-wow-duration="1500ms"
                                        >
                                            <div className={`${"single-service"} ${props.layoutClass}`}>
                                                <span>{item.id}</span>
                                                <div className="icon">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + item.img
                                                        }
                                                        alt="service-icon"
                                                    />
                                                </div>
                                                <h4>{t(item.title)}</h4>
                                                <p className="text-truncate-4">
                                                    {t(item.desc)}
                                                </p>
                                                <div className="read-btn">
                                                    <Link
                                                        to={{
                                                            pathname: '/service/' + `${item.link}`, state: { item }
                                                        }}
                                                        onClick={scrollTop}
                                                    >
                                                        {t("Detay")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </div>
                    </Swiper>
                    <div className="swiper-button-next" />
                    <div className="swiper-button-prev" />
                </div>
            </section>
        </>
    );
}

export default ServiceArea;

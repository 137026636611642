import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./AboutWrap";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Medici Consultancy | Hakkımızda")}</title>
      </Helmet>
      <Breadcrumb pageName={t("Hakkımızda")} pageTitle={t("Hakkımızda")} />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default About;

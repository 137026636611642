import React, { useEffect, useState } from 'react'
import AnimateLine from '../../common/AnimateLine'
import Breadcrumb from '../../common/Breadcrumb'
import SubscribArea from '../../common/SubscribArea'
import ServiceDetailsWrap from './ServiceDetailsWrap'
import ServiceDetailsList from '../../../ServiceList'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

const ServiceDetails = () => {
  const { t } = useTranslation();

  const location = useLocation();

  if (String(typeof (location.state)) != "object") {
    console.log("its not an object")

    return (
      window.location.pathname = "/service"
    )
  }

  let item = location.state.item;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Medici Consultancy | {t(item.title)}</title>
      </Helmet>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb pageName={t("Hizmet Detayı")} pageTitle={t("Hizmet Detayı")} />
        <ServiceDetailsWrap item={item} />
        <SubscribArea />
      </div>
    </>
  )
}

export default ServiceDetails
import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import SubscribArea from '../../common/SubscribArea'
import ProjectWrap from '../project/ProjectWrap'
import PriceCardWrap from '../service/PriceCardWrap'
import TeamWrap from '../team/TeamWrap'
import AboutArea from './AboutArea'
import FeaturesArea from './FeaturesArea'
import HeroArea from './HeroArea'
import LatesPost from './LatesPost'
import PartnarArea from './PartnarArea'
import ServiceArea from './ServiceArea'
import Testimonial from './Testimonial'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function HomePageOne() {
  const { t } = useTranslation();
  return (
    <>
      <div className="creasoft-wrap">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("Medici Consultancy | Yazılım ve Danışmanlık Hizmetleri")}</title>
        </Helmet>
        <AnimateLine />
        <HeroArea />
        <ServiceArea />
        <AboutArea image="/img/logo-dark.svg" />
        <FeaturesArea />
        {/* <ProjectWrap /> */}
        {/* <PartnarArea /> */}
        {/* <PriceCardWrap /> */}
        {/* <Testimonial /> */}
        {/* <TeamWrap /> */}
        {/* <LatesPost /> */}
        <SubscribArea />
      </div>
    </>
  )
}

export default HomePageOne
import React from 'react'
import CountUp from "react-countup";
import { useTranslation } from 'react-i18next';

function FeaturesArea() {

    const { t } = useTranslation();

    return (
        <>
            <section className="features-area">
                <div className="container">
                    <div className="title-wrap  wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="sec-title white">
                            <span>Medici Consultancy</span>
                            <h2>{t("Sayılarla Medici Consultancy")}</h2>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className="single-feature">
                                <div className="feature-inner">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "/img/icons/feature-icon-1.png"} alt="Feature-img" />
                                    </div>
                                    <CountUp end={43} delay={3} duration={5} /> <sup></sup>
                                    <h4>{t("Tamamlanmış Proje")}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div className="single-feature">
                                <div className="feature-inner">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "/img/icons/feature-icon-2.png"} alt="Feature-img" />
                                    </div>
                                    <CountUp end={36} delay={3} duration={5} /> <sup></sup>
                                    <h4>{t("Memnun Müşteri")}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div className="single-feature">
                                <div className="feature-inner">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "/img/icons/feature-icon-3.png"} alt="Feature-img" />
                                    </div>
                                    <CountUp end={16} delay={3} duration={5} /> <sup></sup>
                                    <h4>{t("BT Uzmanı")}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow animate fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
                            <div className="single-feature">
                                <div className="feature-inner">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + '/img/icons/feature-icon-1.png'} alt="Feature-img" />
                                    </div>
                                    <CountUp end={34} delay={3} duration={5} /> <sup></sup>
                                    <h4>{t("Akıllı Çözüm")}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeaturesArea
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'tr',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            tr: {

            },
            en: {
                translation: {
                    "Ana Sayfa": "Home Page",
                    "Hakkımızda": "About Us",
                    "Hizmetlerimiz": "Services",
                    "İletişim": "Contact",
                    "Bize Ulaşın": "Contact Us",
                    "Uzman Danışmanlık": "Expert Consultancy",
                    "Hİzmetlerİ": "Services",
                    "Medici Consultancy olarak, yazılım ve danışmanlık hizmetleri sunmaktayız ve müşterilerimize özelleştirilmiş çözümler sunarak kurumsal hedeflerine ulaşmalarına ve karlılıklarını artırmalarına yardımcı oluyoruz. Medici Consultancy olarak, her zaman en iyi hizmeti sunmak için çaba gösteriyoruz.": "As Medici Consultancy, we provide software and consultancy services, and help our clients reach their corporate goals and increase profitability by offering customized solutions. We always strive to provide the best service possible.",
                    "Çözümlerimiz": "Solutions",
                    "Sunduğumuz Hizmetler": "Our Services",
                    "Detay": "Read More",
                    "Biz Kimiz": "Who Are We",
                    "İstediğiniz her konuda size yardımcı olacak tecrübeli ekibimiz ile ihtiyaç duyduğunuz her an yanınızdayız!": "We are always here for you with our experienced team who can assist you in any topic you need!",
                    "Medici Consultancy, uzun yıllardır faaliyette olan, sektöründe güvenli ve kaliteli hizmet vermeyi kendisine misyon edinmiş bir firmadır.": "Medici Consultancy is a company that has been operating for many years and has made it its mission to provide safe and quality services in its industry.",
                    "Güven, kalite, gerçekçi çözümler üretiyor, uluslararası standartlara uygunluk ilkelerini benimsiyor ve bu ilkeler doğrultusunda sizlere hizmet vermeye özen gösteriyoruz.": "We prioritize trust, quality, and realistic solutions, adopt principles of compliance with international standards, and strive to provide you with services in line with these principles.",
                    "Medici Consultancy; Yazılım ve Danışmanlık": "Medici Consultancy; Software ve Consultancy",
                    "Sayılarla Medici Consultancy": "Medici Consultancy in Numbers",
                    "Tamamlanmış Proje": "Completed Project",
                    "Memnun Müşteri": "Satisfied Client",
                    "BT Uzmanı": "IT Specialist",
                    "Akıllı Çözüm": "Smart Solution",
                    "Size Ulaşalım": "Let Us Contact You",
                    "Gelİşmelerden Haberdar Olmak İçİn": "To stay informed about the developments",
                    "Abone Olun": "Subscribe",
                    "E-posta": "E-mail",
                    "Gönder": "Submit",
                    "Keşfet": "Explore",
                    "Birleşik Krallık": "United Kingdom",
                    "Tüm Hakları Saklıdır": "All Right Reserved",
                    "Yazılım ve Danışmanlık": "Software and Consultancy",
                    "Neden Biz ?": "Why Us ?",
                    "İşletmenizin Başarısı İçin Doğru Tercih": "The Right Choice for the Success of Your Business.",
                    "Medici Consultancy şirketi, en son teknolojik araçları ve sektörel bilgi birikimini kullanarak müşterilerine danışmanlık hizmetleri sunan bir şirkettir. Müşteri memnuniyeti odaklı yaklaşımıyla her müşterinin farklı ihtiyaçlarına uygun çözümler sunar ve işletmelerin büyümesine ve gelişmesine yardımcı olur. Medici Consultancy, güvenilir bir iş ortağı olarak kaliteli hizmetleri ve müşteri odaklı yaklaşımı ile öne çıkar.": "Medici Consultancy is a company that provides consultancy services to its clients by using the latest technological tools and industry knowledge. With a customer-oriented approach, it offers solutions tailored to the unique needs of each client, helping businesses to grow and develop. Medici Consultancy stands out as a reliable business partner with its quality services and customer-focused approach.",
                    "Kesintisiz Destek": "Nonstop Support",
                    "Benzersiz Tasarım": "Unique Design",
                    "SEO Dostu Geliştirme": "SEO-friendly Development",
                    "Aşağıdaki formu doldurarak bizimle iletişime geçebilirsiniz. Ekibimiz en kısa süre içinde sizlere ulaşacaktır.": "You can contact us by filling out the form below. Our team will get back to you as soon as possible.",
                    "İletİşİm Formu": "Contact Form",
                    "İsim": "Name",
                    "Konu": "Subject",
                    "Mesaj": "Message",
                    "Adres": "Address",
                    "Telefon Numarası": "Phone Number",
                    "E-posta Adresi": "E-Mail",
                    "Tüm Hİzmetler": "All Services",
                    "Bİlgİ Almak İçİn": "To Get Information",
                    "Bİze Ulaşın": "Contact Us",
                    "Yazılım Geliştirme": "Software Development",
                    "Medici Consultancy, müşterilerine özelleştirilmiş yazılım çözümleri sunarak, iş süreçlerinin optimize edilmesine yardımcı olur. Medici Consultancy, müşterilerinin iş ihtiyaçlarını anlayarak, onlara en uygun yazılım çözümlerini sunar ve yazılım geliştirme, danışmanlık ve destek hizmetleri sağlar.": "Medici Consultancy provides customized software solutions to its clients to help optimize business processes. By understanding the business needs of its clients, Medici Consultancy offers them the most suitable software solutions and provides software development, consulting, and support services.",
                    "Medici Consultancy, müşterilerinin farklı sektörlerdeki iş süreçlerini daha etkili hale getirmelerine yardımcı olur. Bu hizmet, müşterilerin verimliliğini artırırken, hataları azaltarak maliyetleri düşürür ve müşterilerin iş performansını optimize eder.": "Medici Consultancy helps its clients to make their business processes more effective in different sectors. This service increases the productivity of the clients while reducing costs by minimizing errors, and optimizes the business performance of the clients.",
                    "Müşterilere sunulan yazılım çözümleri, web uygulamaları, mobil uygulamalar ve diğer yazılım araçları içerir. Medici Consultancy, müşterilerin ihtiyaçlarına uygun yazılım çözümleri sunarak, onların iş süreçlerindeki zorlukları aşmalarına yardımcı olur ve verimliliklerini artırır.": "The software solutions offered to customers include web applications, mobile applications, and other software tools. Medici Consultancy helps customers overcome challenges in their business processes and increases their productivity by providing software solutions that meet their needs.",
                    "Ayrıca, Medici Consultancy müşterilerine yazılım geliştirme, danışmanlık ve destek hizmetleri sunar. Yazılım geliştirme hizmetleri, müşterilerin ihtiyaçlarına uygun özelleştirilmiş yazılım çözümleri geliştirerek, iş süreçlerini optimize etmelerine yardımcı olur. Danışmanlık hizmetleri ise, müşterilerin iş süreçlerini analiz ederek, işlerinin verimliliğini artırmak için önerilerde bulunur. Destek hizmetleri ise, müşterilerin yazılım çözümlerini kullanırken karşılaştıkları herhangi bir sorunu çözmelerine yardımcı olur.": "Additionally, Medici Consultancy offers software development, consulting, and support services to its clients. The software development services help clients optimize their business processes by developing customized software solutions that meet their specific needs. The consulting services analyze clients' business processes and provide recommendations to improve their efficiency. The support services assist clients in resolving any issues they encounter while using the software solutions.",
                    "Medici Consultancy'nin müşterilerine sunduğu yazılım geliştirme hizmetleri, iş süreçlerinde verimliliği artırarak müşterilerin rekabet avantajını artırır ve sektörde öne çıkmalarına yardımcı olur.": "The software development services provided by Medici Consultancy help increase efficiency in business processes, enabling customers to gain a competitive advantage and stand out in their industry.",
                    "Proje Yönetimi": "Project Management",
                    "Medici Consultancy, müşterilerine proje yönetimi hizmeti sunarak, projelerinin başarılı bir şekilde tamamlanmasına yardımcı olur. Müşterilerinin ihtiyaçlarına göre özelleştirilmiş proje yönetimi çözümleri sunan Medici Consultancy, projelerin zamanında, bütçe dahilinde ve kaliteli bir şekilde tamamlanmasını sağlar.": "Medici Consultancy provides project management services to its clients to assist in the successful completion of their projects. Offering customized project management solutions tailored to the needs of its clients, Medici Consultancy ensures that projects are completed on time, within budget, and to a high standard of quality.",
                    "Medici Consultancy, proje yönetimi hizmetleriyle müşterilerinin proje risklerini azaltarak, proje takibini kolaylaştırır ve projelerin başarılı bir şekilde tamamlanmasını sağlar. Proje yönetimi hizmetleri, müşterilerin ihtiyaçlarına göre özelleştirilir ve her aşamada müşterilerin proje yönetimine dahil olmasını sağlar.": "Medici Consultancy reduces project risks and facilitates project tracking for its clients through its project management services, ensuring successful project completion. Its project management services are customized to meet the specific needs of clients and involve their active involvement at every stage of the project management process.",
                    "Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin ihtiyaçlarına göre farklı sektörlerdeki projeler için özelleştirilir. Bu hizmetler, müşterilerin projelerinin tamamlanması için gerekli olan kaynakları belirlemelerine, proje bütçelerini yönetmelerine ve proje risklerini minimize etmelerine yardımcı olur.": "Medici Consultancy's project management services are customized for projects in different sectors according to the needs of its clients. These services help clients identify the resources required for project completion, manage project budgets, and minimize project risks.",
                    "Medici Consultancy, müşterilerine proje yönetimi hizmeti sunarken, müşterilerinin projelerinin tüm aşamalarında destek olur. Bu destek, proje planlamasından proje sonlandırılmasına kadar süren tüm süreçleri kapsar. Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin projelerinin başarılı bir şekilde tamamlanmasına yardımcı olur ve müşterilerin proje yönetimi konusunda uzmanlaşmalarına olanak tanır.": "Medici Consultancy provides project management services to its clients while supporting them throughout all stages of their projects. This support covers all processes from project planning to project closure. Medici Consultancy's project management services assist clients in successfully completing their projects and enable them to specialize in project management.",
                    "Sonuç olarak, Medici Consultancy'nin müşterilerine sunduğu proje yönetimi hizmetleri, müşterilerin proje yönetimindeki zorlukları aşmalarına yardımcı olur ve projelerinin başarılı bir şekilde tamamlanmasını sağlar. Medici Consultancy, müşterilerinin iş ihtiyaçlarını anlayarak, proje yönetimi çözümlerini özelleştirir ve müşterilerine proje yönetimi konusunda uzmanlık sağlar.": "In conclusion, Medici Consultancy's project management services help its clients overcome challenges in project management and ensure successful project completion. By understanding its clients' business needs, Medici Consultancy customizes project management solutions and provides expertise to clients in project management.",
                    "Siber Güvenlik": "Cyber Security",
                    "Medici Consultancy, müşterilerine siber güvenlik hizmeti sunarak, dijital varlıklarının güvenliğini sağlar. Müşterilerin ihtiyaçlarına göre özelleştirilen siber güvenlik çözümleri sunan Medici Consultancy, müşterilerin siber saldırılardan korunmasına yardımcı olur.": "Medici Consultancy ensures the security of its clients' digital assets by providing them with cybersecurity services. Offering customized cybersecurity solutions tailored to the needs of its clients, Medici Consultancy assists clients in protecting themselves against cyber attacks.",
                    "Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin dijital varlıklarını korumak için farklı araçlar kullanarak saldırıları engeller. Bu araçlar arasında, güvenlik duvarları, antivirüs yazılımları, zararlı yazılım koruması, veri şifreleme, izleme ve raporlama araçları yer alır.": "Medici Consultancy's cybersecurity services prevent attacks on clients' digital assets by using various tools. These tools include firewalls, antivirus software, malware protection, data encryption, monitoring, and reporting tools.",
                    "Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit ederek, siber saldırıların önlenmesine yardımcı olur. Bu uzmanlar, müşterilerin mevcut güvenlik sistemlerini değerlendirir ve ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur.": "The cybersecurity experts of Medici Consultancy help prevent cyber attacks by identifying clients' security vulnerabilities. These experts evaluate clients' existing security systems and assist in determining necessary security measures.",
                    "Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin ihtiyaçlarına göre özelleştirilir ve her aşamada müşterilerin siber güvenlik sistemlerine dahil olmasını sağlar. Müşterilere, siber güvenlik konusunda danışmanlık, eğitim ve destek hizmetleri de sunulur.": "Medici Consultancy's cybersecurity services are customized to meet the needs of clients and ensure their involvement in the cybersecurity systems at every stage. Clients are also offered consultancy, training, and support services on cybersecurity.",
                    "Sonuç olarak, Medici Consultancy'nin müşterilerine sunduğu siber güvenlik hizmetleri, müşterilerin dijital varlıklarının güvenliğini sağlayarak, siber saldırılardan korunmalarına yardımcı olur. Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit eder ve ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur. Bu hizmetler, müşterilerin iş sürekliliğini sağlar ve itibarlarını korur.": "As a result, Medici Consultancy's cybersecurity services help customers secure their digital assets and protect them from cyber attacks. Medici Consultancy's cybersecurity experts identify security vulnerabilities and assist in determining the necessary security measures at each stage of the process, while providing customers with cybersecurity consulting, training, and support services. These services ensure business continuity and protect their reputation.",
                    "Santral": "Central",
                    "Medici Consultancy, müşterilerine IP santral hizmeti sunarak, iletişim altyapılarını modernize etmelerine yardımcı olur. IP santral hizmeti, geleneksel telefon sistemlerinin yerini alan ve internet üzerinden ses ve veri iletişimini sağlayan bir teknolojidir.": "Medici Consultancy provides IP PBX services to its clients, helping them modernize their communication infrastructure. IP PBX service is a technology that replaces traditional telephone systems and enables voice and data communication over the internet.",
                    "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iş süreçlerine uygun şekilde tasarlanır. Bu hizmet sayesinde müşteriler, çağrıları yönetebilir, telefon konferansları düzenleyebilir, çağrı kayıtlarını tutabilir ve mesajlaşma özellikleriyle daha verimli bir iletişim sağlayabilirler.": "Medici Consultancy's IP PBX services are designed to meet the specific needs of its clients' business processes. With this service, clients can manage calls, organize phone conferences, keep call records, and have more efficient communication with messaging features.",
                    "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iletişim sistemlerini daha güvenli hale getirir. IP santral hizmeti, geleneksel telefon sistemlerine göre daha güvenli bir iletişim sağlar ve müşterilerin iş sürekliliğini korumasına yardımcı olur.": "Medici Consultancy's IP PBX services make clients' communication systems more secure. IP PBX service provides a more secure communication compared to traditional phone systems and helps clients maintain business continuity.",
                    "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iletişim altyapılarını modernize ederek, iş süreçlerinde zaman ve maliyet tasarrufu sağlar. Ayrıca müşterilere 7/24 teknik destek hizmeti sunularak, olası sorunlar en kısa sürede çözülür ve müşterilerin iş sürekliliği sağlanır.": "Medici Consultancy's IP PBX services help customers modernize their communication infrastructure, providing time and cost savings in their business processes. In addition, customers are provided with 24/7 technical support services, allowing for any potential issues to be resolved quickly and ensuring business continuity.",
                    "Medici Consultancy, müşterilerine web hosting hizmeti sunarak, web sitelerinin kesintisiz bir şekilde yayınlanmasını sağlar. Web hosting hizmeti, web sitelerinin internet üzerindeki erişilebilirliğini sağlayan bir hizmettir.": "Medici Consultancy provides web hosting services to its clients, ensuring uninterrupted publication of their websites. Web hosting service is a service that provides internet accessibility to websites.",
                    "Medici Consultancy'nin web hosting hizmetleri, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır. Bu hizmetler, müşterilerin web sitelerinin trafiği, depolama alanı ve diğer gereksinimleri göz önünde bulundurularak planlanır. Ayrıca Medici Consultancy, müşterilerinin web sitelerini yönetmelerine yardımcı olacak araçlar ve uygulamalar sağlar.": "Medici Consultancy's web hosting services are designed according to the needs of customers. These services are planned considering the traffic, storage space, and other requirements of customers' websites. Additionally, Medici Consultancy provides tools and applications to help customers manage their websites.",
                    "Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin güvenliğini sağlar. Web sitelerinin güvenliği, günümüzde büyük bir önem taşımaktadır. Bu nedenle, Medici Consultancy, müşterilerinin web sitelerinin güvenliği için gerekli olan SSL sertifikaları ve diğer güvenlik araçlarını sağlar.": "Medici Consultancy's web hosting services ensure the security of clients' websites. Website security is of great importance today. Therefore, Medici Consultancy provides clients with SSL certificates and other security tools necessary for the security of their websites.",
                    "Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin yüksek performanslı çalışmasını sağlar. Web sitelerinin hızlı yüklenmesi, kullanıcı deneyimi için büyük önem taşır. Bu nedenle, Medici Consultancy, müşterilerinin web sitelerinin hızlı ve kesintisiz bir şekilde yayınlanmasını sağlayacak altyapı ve teknolojileri kullanır.": "Medici Consultancy's web hosting services ensure that customers' websites operate at high performance. Fast loading of websites is critical for user experience. Therefore, Medici Consultancy utilizes infrastructure and technologies that enable customers' websites to be published quickly and seamlessly.",
                    "Sonuç olarak, Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin güvenli, hızlı ve kesintisiz bir şekilde yayınlanmasını sağlar. Bu hizmetler, müşterilerin web sitelerini yönetmelerine yardımcı olacak araçlar, SSL sertifikaları ve diğer güvenlik araçları gibi özellikleri içerir.": "In conclusion, Medici Consultancy's web hosting services ensure that customers' websites are secure, fast, and available without interruption. These services include features such as tools to help customers manage their websites, SSL certificates, and other security measures.",
                    "Medici Consultancy, müşterilerine network hizmeti sunarak, işletmelerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamalarına yardımcı olur. Network hizmetleri, işletmelerin iş süreçlerini optimize etmeleri ve verimliliklerini artırmalarına yardımcı olan kritik bir bileşendir.": "Medici Consultancy offers network services to its clients to help businesses meet their communication and information sharing needs. Network services are a critical component that helps businesses optimize their processes and increase their productivity.",
                    "Medici Consultancy'nin network hizmetleri, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır. Bu hizmetler, müşterilerin ağlarındaki performans, güvenlik ve diğer gereksinimleri göz önünde bulundurularak planlanır. Ayrıca, Medici Consultancy, müşterilerinin network altyapılarının yönetimini de üstlenir.": "Medici Consultancy's network services are designed to meet the needs of its customers. These services are planned by considering the performance, security, and other requirements of the customers' networks. Additionally, Medici Consultancy also takes over the management of its customers' network infrastructure.",
                    "Medici Consultancy'nin network hizmetleri, müşterilerin ağlarının güvenliğini sağlar. Ağ güvenliği, işletmeler için kritik bir konudur. Bu nedenle, Medici Consultancy, müşterilerinin ağlarını korumak için gerekli olan güvenlik araçlarını sağlar.": "Medici Consultancy's network services ensure the security of clients' networks. Network security is a critical issue for businesses. Therefore, Medici Consultancy provides clients with the necessary security tools to protect their networks.",
                    "Medici Consultancy'nin network hizmetleri, müşterilerin ağlarının performansını artırır. Ağ performansı, işletmelerin verimliliği ve iş süreçlerinin optimize edilmesi için önemlidir. Medici Consultancy, müşterilerin ağlarının hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli altyapı ve teknolojileri kullanır.": "Medici Consultancy's network services improve the performance of clients' networks. Network performance is important for businesses to increase productivity and optimize their processes. Medici Consultancy uses the necessary infrastructure and technologies to ensure that clients' networks run quickly and seamlessly.",
                    "Sonuç olarak, Medici Consultancy'nin network hizmetleri, müşterilerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamalarına yardımcı olur. Bu hizmetler, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve ağların performansı, güvenliği ve yönetimi için gerekli araçları sağlar. Medici Consultancy, müşterilerin ağlarının güvenli, hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli teknolojileri kullanır.": "Overall, Medici Consultancy's network services help customers meet their communication and information sharing needs. These services are designed to meet the customers' requirements and are planned according to the performance, security, and other requirements of their networks. Additionally, Medici Consultancy takes on the management of customers' network infrastructure. The network services provided by Medici Consultancy ensure that the customers' networks are secure, performant, and efficiently managed. Medici Consultancy employs the necessary infrastructure and technologies to ensure that the customers' networks operate securely, quickly, and seamlessly.",
                    "Medici Consultancy, müşterilerine CRM (Müşteri İlişkileri Yönetimi) hizmetleri sunarak işletmelerin müşterileriyle daha iyi etkileşim kurmalarına ve müşteri memnuniyetini artırmalarına yardımcı olmaktadır. CRM hizmetleri, müşterilerin ihtiyaçlarına göre tasarlanır ve işletmelerin müşteri verilerini yönetmelerini, müşteri bilgilerini takip etmelerini ve müşteri etkileşimlerini analiz etmelerini sağlar.": "Medici Consultancy provides CRM (Customer Relationship Management) services to its clients to help businesses better interact with their customers and increase customer satisfaction. CRM services are designed to meet the specific needs of clients and enable businesses to manage customer data, track customer information, and analyze customer interactions.",
                    "Medici Consultancy, müşterilerine CRM hizmetleri sunarken, müşterilerinin mevcut müşteri verilerini analiz ederek, müşterilerine özel çözümler sunar. Bu sayede müşteriler, işletmelerinde müşteri ilişkileri yönetimi süreçlerini otomatikleştirebilir, müşteri verilerini güvenli bir şekilde saklayabilir ve müşteri etkileşimlerini daha verimli hale getirebilirler.": "Medici Consultancy provides CRM (Customer Relationship Management) services to its clients to help businesses interact with their customers better and improve customer satisfaction. CRM services are designed according to the needs of clients, and enable businesses to manage customer data, track customer information, and analyze customer interactions. Additionally, when providing CRM services, Medici Consultancy offers personalized solutions by analyzing clients' existing customer data. This allows clients to automate customer relationship management processes, securely store customer data, and make customer interactions more efficient.",
                    "Medici Consultancy'nin CRM hizmetleri, işletmelerin müşteri memnuniyetini artırmalarına yardımcı olmanın yanı sıra, müşterilerin satış süreçlerini optimize etmelerine ve gelirlerini artırmalarına da katkı sağlar. Bu hizmetler, müşterilerin ihtiyaçlarına uygun olarak tasarlanır ve işletmelerin müşterileriyle daha iyi bir ilişki kurmalarına ve pazarlama stratejilerini geliştirmelerine yardımcı olur. Medici Consultancy, müşterilerinin işletme hedeflerine ulaşmalarına yardımcı olmak için müşteri odaklı ve esnek çözümler sunar.": "Medici Consultancy's CRM services not only help businesses improve customer satisfaction, but also contribute to optimizing their sales processes and increasing revenue. These services are designed according to the needs of customers and help businesses establish better relationships with their customers and develop marketing strategies. Medici Consultancy offers customer-focused and flexible solutions to help its customers achieve their business goals.",
                    "Medici Consultancy, müşterilerine DevOps hizmetleri sunarak, işletmelerin yazılım geliştirme ve operasyonel süreçlerini entegre etmelerine yardımcı olur. DevOps, geliştirme ve operasyonel ekiplerin işbirliği yaparak hızlı, güvenilir ve sürekli yazılım dağıtımı sağlamalarına olanak tanıyan bir kültür ve yöntemler bütünüdür.": "Medici Consultancy provides DevOps services to its clients, helping businesses integrate their software development and operational processes. DevOps is a culture and set of practices that enables development and operational teams to collaborate and deliver rapid, reliable, and continuous software deployment.",
                    "Medici Consultancy, müşterilerine bu süreçte yardımcı olmak için, geliştirme, test ve operasyonel süreçlerin tamamını entegre eden DevOps hizmetleri sunar. Müşterilerin yazılım geliştirme sürecinde zaman, bütçe ve kaynak tasarrufu sağlamalarına yardımcı olur. Ayrıca, müşterilerin yazılım dağıtım süreçlerini hızlandırmalarına ve daha yüksek kaliteli yazılım teslim etmelerine olanak tanır.": "Medici Consultancy provides DevOps services to its customers, helping them integrate their software development and operational processes. DevOps is a culture and set of practices that allows development and operational teams to collaborate, enabling fast, reliable, and continuous software deployment. To assist customers in this process, Medici Consultancy offers DevOps services that integrate the entire development, testing, and operational process.It helps customers save time, budget, and resources in their software development process.Additionally, it enables customers to speed up their software deployment processes and deliver higher quality software.",
                    "Medici Consultancy, müşterilerine özel DevOps stratejileri sunarak, işletmelerin hedeflerine ve ihtiyaçlarına uygun çözümler geliştirir. Bu çözümler, işletmelerin yazılım geliştirme süreçlerini optimize ederek, daha hızlı ve daha güvenli yazılım dağıtımı yapmalarına olanak sağlar.": "Medici Consultancy provides customized DevOps strategies to its clients, developing solutions that align with their goals and needs. These solutions optimize the software development process of businesses, enabling them to deploy software faster and more securely.",
                    "Sonuç olarak, Medici Consultancy'nin DevOps hizmetleri müşterilerin yazılım geliştirme ve operasyonel süreçlerini entegre etmelerine yardımcı olur. Bu hizmetler, müşterilerin yazılım geliştirme süreçlerini hızlandırmalarına, daha yüksek kaliteli yazılım teslim etmelerine ve maliyetlerini azaltmalarına olanak tanır. Medici Consultancy, müşterilerine özel çözümler sunarak, işletmelerin hedeflerine ulaşmalarına yardımcı olur.": "In conclusion, Medici Consultancy's DevOps services help customers integrate their software development and operational processes. These services enable customers to accelerate their software development processes, deliver higher quality software, and reduce costs. Medici Consultancy provides customized solutions to its customers, helping them to achieve their business goals.",
                    "Medici Consultancy, müşterilerine Trading Platforms hizmeti sunarak finansal kurumlar ve yatırımcıların ihtiyaçlarına uygun çözümler sunar. Bu hizmetler, müşterilerin borsa ve finans piyasalarındaki ticaret faaliyetlerini optimize eder, daha hızlı ve güvenli işlemler yapmalarına olanak tanır. Medici Consultancy, müşterilerine özel Trading Platforms stratejileri sunarak işletmelerin ihtiyaçlarına ve hedeflerine uygun çözümler geliştirir.": "Medici Consultancy provides Trading Platforms services to its customers by offering solutions tailored to the needs of financial institutions and investors. These services optimize customers' trading activities in stock and financial markets, allowing for faster and more secure transactions. Medici Consultancy develops custom Trading Platforms strategies for its clients to provide solutions that meet their specific needs and goals.",
                    "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin ticaret stratejilerini otomatikleştirmelerine olanak tanıyan algoritmik işlem platformları da dahil olmak üzere bir dizi farklı hizmeti kapsar. Bu platformlar, müşterilerin yatırımlarını daha verimli bir şekilde yönetmelerine ve daha akıllıca yatırım kararları almalarına yardımcı olur.": "Medici Consultancy's Trading Platforms services cover a range of different solutions tailored to the needs of financial institutions and investors, including algorithmic trading platforms that allow customers to automate their trading strategies. These platforms help customers manage their investments more efficiently and make smarter investment decisions.",
                    "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin özelleştirilmiş ticaret stratejileri oluşturmasına ve bu stratejileri daha etkili bir şekilde uygulamalarına olanak tanır. Müşteriler, hızlı ve güvenilir veri akışı sayesinde piyasaların anlık durumunu takip edebilir ve daha iyi yatırım kararları alabilirler.": "Medici Consultancy's Trading Platforms services allow customers to create customized trading strategies and apply them more effectively. Customers can track real-time market conditions and make better investment decisions thanks to fast and reliable data streams.",
                    "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin yatırım faaliyetlerinin hızını ve etkinliğini artırmalarına yardımcı olurken, aynı zamanda güvenliği de sağlar. Medici Consultancy, müşterilerinin güvenliğini önemseyerek, platformlarına üst düzey güvenlik önlemleri ekler. Böylece müşterilerinin finansal verileri ve yatırımları her zaman güvence altında olur.": "Medici Consultancy's Trading Platforms services help customers increase the speed and efficiency of their investment activities while also ensuring security. Medici Consultancy prioritizes the security of its customers and adds top-level security measures to its platforms. This way, customers' financial data and investments are always secure.",
                    "Medici Consultancy, müşterilerine güvenli, hızlı ve yüksek performanslı bir bulut hizmeti sunarak, işletmelerin daha esnek ve ölçeklenebilir bir altyapıya sahip olmalarını sağlıyor. Cloud Services hizmetleri kapsamında, müşteri ihtiyaçlarına özel özelleştirilebilir bir bulut altyapısı sunuyor, veri yedekleme ve depolama çözümleri sağlıyor, uygulamaların ve verilerin güvenliğini korumak için gerekli önlemleri alıyor ve müşterilerine teknik destek hizmeti sunuyor.": "Medici Consultancy provides its clients with a secure, fast, and high-performance cloud service, enabling businesses to have a more flexible and scalable infrastructure. Under the Cloud Services, the company offers customizable cloud infrastructure tailored to the clients' needs, provides data backup and storage solutions, takes necessary security measures to protect applications and data, and offers technical support services.",
                    "Medici Consultancy'nin sunduğu bulut hizmeti, işletmelerin kaynaklarını verimli bir şekilde kullanmalarına ve işlerini geliştirmelerine yardımcı oluyor. Bulut hizmetleri, esnek bir ödeme modeli sunarak işletmelerin maliyetlerini optimize etmelerine de yardımcı oluyor. Ayrıca, işletmelerin ölçeklenebilirliği artırarak, büyüme hedeflerine daha hızlı ulaşmalarına yardımcı oluyor.": "Medici Consultancy's cloud service enables businesses to use their resources efficiently and helps them improve their operations. Within the scope of Cloud Services, it provides customers with a customizable cloud infrastructure tailored to their needs, offers data backup and storage solutions, takes necessary measures to ensure the security of applications and data, and provides technical support services. Cloud services also help businesses optimize costs by offering a flexible payment model. Additionally, by increasing scalability, it helps businesses achieve their growth goals faster.",
                    "Medici Consultancy'nin Cloud Services hizmeti, işletmelerin ihtiyaçlarına özel olarak tasarlanmış bir bulut altyapısı sunarak, müşterilerin verimliliğini artırmasına ve rekabet avantajı elde etmesine yardımcı oluyor.": "Medici Consultancy's Cloud Services offer a cloud infrastructure specifically designed for the needs of businesses, helping customers to increase their efficiency and gain a competitive advantage.",
                    "Hizmet Detayı": "Service Detail",
                    "Medici Consultancy, müşterilerine özelleştirilmiş yazılım çözümleri sunarak, iş süreçlerinin optimize edilmesine yardımcı olur. Müşterilere web ve mobil uygulamalar gibi farklı yazılım araçları sunulur ve yazılım geliştirme, danışmanlık ve destek hizmetleri sağlanır. Bu hizmetler, müşterilerin iş süreçlerinde verimliliği artırır ve sektörde rekabet avantajı sağlar.": "Medici Consultancy provides custom software solutions to its clients, helping to optimize their business processes. Clients are offered various software tools such as web and mobile applications, and software development, consulting, and support services are provided. These services increase efficiency in clients' business processes and provide a competitive advantage in the industry.",
                    "Medici Consultancy, müşterilerine özelleştirilmiş proje yönetimi çözümleri sunarak, projelerinin zamanında, bütçe dahilinde ve kaliteli bir şekilde tamamlanmasını sağlar. Bu hizmetler müşterilerin proje risklerini azaltır, proje takibini kolaylaştırır ve proje bütçelerini yönetmelerine yardımcı olur. Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin proje yönetimi konusunda uzmanlaşmalarına olanak tanır ve müşterilerin iş ihtiyaçlarını anlayarak özelleştirilmiş proje yönetimi çözümleri sunar.": "Medici Consultancy provides customized project management solutions to its clients to ensure timely, budgeted, and quality completion of their projects. These services reduce project risks, facilitate project tracking, and help manage project budgets. Medici Consultancy's project management services allow clients to specialize in project management and provide tailored project management solutions by understanding their business needs.",
                    "Medici Consultancy, müşterilerine özelleştirilmiş siber güvenlik hizmetleri sunarak, dijital varlıklarının güvenliğini sağlar. Bu hizmetler arasında güvenlik duvarları, antivirüs yazılımları, zararlı yazılım koruması, veri şifreleme, izleme ve raporlama araçları yer alır. Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit ederek, ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur. Müşterilere ayrıca siber güvenlik konusunda danışmanlık, eğitim ve destek hizmetleri de sunulur. Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin iş sürekliliğini sağlar ve itibarlarını korur.": "Medici Consultancy provides customized cybersecurity services to its clients, ensuring the security of their digital assets. These services include firewalls, antivirus software, malware protection, data encryption, monitoring, and reporting tools. Medici Consultancy's cybersecurity experts help identify clients' security vulnerabilities and determine the necessary security measures. Additionally, clients receive cybersecurity consulting, training, and support services. Medici Consultancy's cybersecurity services ensure the continuity of clients' businesses and protect their reputations.",
                    "Medici Consultancy, müşterilerine IP santral hizmeti sunarak, iletişim altyapılarını modernize etmelerine yardımcı olur. IP santral hizmeti, internet üzerinden ses ve veri iletişimini sağlayan bir teknolojidir. Medici Consultancy'nin IP santral hizmetleri, müşterilerin iş süreçlerine uygun şekilde tasarlanır, çağrı yönetimi, telefon konferansları, çağrı kaydı, mesajlaşma özellikleri gibi avantajlar sağlar. IP santral hizmeti, geleneksel telefon sistemlerine göre daha güvenli bir iletişim sağlar ve müşterilerin iş sürekliliğini korumasına yardımcı olur. Ayrıca Medici Consultancy, müşterilerine 7/24 teknik destek hizmeti sunarak, iş sürekliliği sağlamalarına yardımcı olur.": "Medici Consultancy provides IP PBX services to its clients, helping them modernize their communication infrastructure. IP PBX service is a technology that enables voice and data communication over the internet. Medici Consultancy's IP PBX services are designed to meet the specific needs of clients' business processes and offer benefits such as call management, conference calling, call recording, messaging features, and more. IP PBX service provides a more secure communication compared to traditional telephone systems and helps clients maintain business continuity. Additionally, Medici Consultancy offers 24/7 technical support services to its clients, helping them ensure business continuity.",
                    "Medici Consultancy, müşterilerine web hosting hizmeti sunarak web sitelerinin kesintisiz bir şekilde yayınlanmasını sağlar. Bu hizmet, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve web sitelerinin güvenliği, performansı ve yönetimi için gerekli araçlar sağlanır. Müşterilerin web sitelerinin güvenli, hızlı ve kesintisiz bir şekilde yayınlanması için SSL sertifikaları, güvenlik araçları, hızlı altyapı ve teknolojiler kullanılır.": "Medici Consultancy provides web hosting services to its clients, ensuring uninterrupted publishing of their websites. This service is tailored to meet the specific needs of clients and provides necessary tools for website security, performance, and management. To ensure secure, fast, and uninterrupted website publishing for clients, SSL certificates, security tools, fast infrastructure, and technologies are utilized.",
                    "Medici Consultancy, müşterilerine network hizmetleri sunarak, ağlarının performansını, güvenliğini ve yönetimini sağlamakta ve işletmelerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamaktadır. Hizmetler, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve ağlarının güvenli, hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli teknolojiler kullanılır. Ağ güvenliği, işletmeler için kritik bir konudur ve Medici Consultancy, müşterilerinin ağlarını korumak için gerekli olan güvenlik araçlarını sağlar.": "Medici Consultancy provides network services to its clients, ensuring the performance, security, and management of their networks, and meeting their communication and information sharing needs. The services are designed according to the clients' needs, and the necessary technologies are used to ensure the security, speed, and uninterrupted operation of their networks. Network security is a critical issue for businesses, and Medici Consultancy provides clients with the necessary security tools to protect their networks.",
                    "Medici Consultancy, müşterilerine özel CRM (Müşteri İlişkileri Yönetimi) hizmetleri sunmaktadır. Bu hizmetler işletmelerin müşteri verilerini yönetmelerini, müşteri etkileşimlerini analiz etmelerini ve müşteri memnuniyetini artırmalarını sağlar. Medici Consultancy, müşterilerin mevcut müşteri verilerini analiz ederek özel çözümler sunar ve işletmelerin müşteri ilişkileri yönetimi süreçlerini otomatikleştirmelerine yardımcı olur. Bu hizmetler müşterilerin satış süreçlerini optimize etmelerine ve gelirlerini artırmalarına da katkı sağlar. Medici Consultancy, müşteri odaklı ve esnek çözümler sunarak işletmelerin hedeflerine ulaşmalarına yardımcı olur.": "Medici Consultancy offers customized CRM (Customer Relationship Management) services to its customers. These services enable businesses to manage customer data, analyze customer interactions, and improve customer satisfaction. Medici Consultancy provides tailored solutions by analyzing the customers' existing customer data and helps automate the customer relationship management process for businesses. These services also contribute to optimizing customers' sales processes and increasing their revenues. Medici Consultancy offers customer-focused and flexible solutions to help businesses achieve their goals.",
                    "Medici Consultancy, müşterilerine DevOps hizmetleri sunarak yazılım geliştirme ve operasyonel süreçlerin entegrasyonunu sağlar. Bu hizmetler, müşterilerin yazılım geliştirme süreçlerini optimize eder, daha hızlı ve güvenli yazılım dağıtımı yapmalarına olanak tanır. Medici Consultancy, müşterilerine özel DevOps stratejileri sunarak işletmelerin ihtiyaçlarına ve hedeflerine uygun çözümler geliştirir.": "Medici Consultancy provides DevOps services to its clients, enabling the integration of software development and operational processes. These services optimize clients' software development processes and enable faster and more secure software deployment. Medici Consultancy develops customized DevOps strategies for clients based on their needs and goals.",
                    "Medici Consultancy, müşterilerine yüksek performanslı ve güvenli bir ticaret platformu sunarak, finansal piyasalarda rekabet avantajı elde etmelerini sağlıyor. Trading Platforms hizmetleri kapsamında, müşteri ihtiyaçlarına özel özelleştirilebilir yazılımlar geliştiriyor, platformların bakım ve güncelleme işlemlerini gerçekleştiriyor, mevcut sistemlerin iyileştirilmesine yardımcı oluyor ve müşterilerine teknik destek hizmeti sunuyor. Bu sayede müşteriler, kendi işlerine odaklanırken, Medici Consultancy tarafından sunulan hizmetler sayesinde finansal piyasalarda başarılı olabiliyorlar.": "Medici Consultancy provides its clients with a high-performance and secure trading platform, enabling them to gain a competitive advantage in financial markets. As part of its Trading Platforms services, the company develops customizable software tailored to client needs, performs maintenance and updates of the platforms, assists with the improvement of existing systems, and offers technical support services to its clients. This allows clients to focus on their business while benefiting from the services provided by Medici Consultancy and achieving success in financial markets.",
                    "Medici Consultancy, müşterilerine bulut hizmeti sunarak işletmelerin daha esnek ve ölçeklenebilir bir altyapıya sahip olmalarını sağlıyor. Bulut hizmetleri kapsamında özelleştirilebilir bir altyapı, veri yedekleme ve depolama çözümleri, güvenlik önlemleri ve teknik destek hizmeti sunuluyor. Bu hizmetler işletmelerin kaynaklarını verimli bir şekilde kullanmalarına, ölçeklenebilirliği artırmalarına ve maliyetleri optimize etmelerine yardımcı oluyor.": "Medici Consultancy provides cloud services to its customers, enabling businesses to have a more flexible and scalable infrastructure. Within the scope of cloud services, a customizable infrastructure, data backup and storage solutions, security measures, and technical support services are offered. These services help businesses to use their resources efficiently, increase scalability, and optimize costs.",
                    "Medici Consultancy | Yazılım ve Danışmanlık Hizmetleri": "Medici Consultancy | Software and Consultancy Services",
                    "Medici Consultancy | Hakkımızda": "Medici Consultancy | About Us",
                    "Medici Consultancy | Hizmetlerimiz": "Medici Consultancy | Our Services",
                    "Medici Consultancy | İletişim": "Medici Consultancy | Contact",
                    "Londra": "London"
                }
            },
        }
    });

export default i18n;
import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from 'react-i18next';

function AboutArea(props) {
    const [isOpen, setOpen] = useState(false)

    const { t } = useTranslation();

    return (
        <>
            <section className="about-area sec-mar-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className={`${"sec-title layout2"} ${props.white}`}>
                                <span>{t("Biz Kimiz")}</span>
                                <h2>{t("Hakkımızda")}</h2>
                            </div>
                            <div className={`${"about-left"} ${props.layoutClass}`}>
                                <h3>{t("İstediğiniz her konuda size yardımcı olacak tecrübeli ekibimiz ile ihtiyaç duyduğunuz her an yanınızdayız!")}</h3>
                                <p>{t("Medici Consultancy, uzun yıllardır faaliyette olan, sektöründe güvenli ve kaliteli hizmet vermeyi kendisine misyon edinmiş bir firmadır.")} <br /> {t("Güven, kalite, gerçekçi çözümler üretiyor, uluslararası standartlara uygunluk ilkelerini benimsiyor ve bu ilkeler doğrultusunda sizlere hizmet vermeye özen gösteriyoruz.")}</p>
                                {/* <div className={`${"company-since"} ${props.layoutClass}`}>
                                    <div className="company-logo">
                                        <img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="about-images" />
                                    </div>
                                    <strong style={{ color: "#75dab4", fontSize: "26px", fontWeight: "600" }}>2023</strong>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4>{t("Medici Consultancy; Yazılım ve Danışmanlık")}</h4>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className="about-right">
                                <div className="banner-1">
                                    <img src={process.env.PUBLIC_URL + '/img/about-baner-1.jpg'} alt="about-images" />
                                </div>
                                <div className="banner-2">
                                    <img src={process.env.PUBLIC_URL + '/img/about-baner-2.jpg'} alt="about-images" />
                                    {/* <div className="banner2-inner">
                                        <div className="play">
                                            <div className="video-popup" onClick={() => setOpen(true)} ><i className="fas fa-play" /></div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <React.Fragment>
                <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="IUN664s7N-c"
                    autoplay={1}
                    onClose={() => setOpen(false)}
                />
            </React.Fragment> */}
        </>
    )
}

export default AboutArea
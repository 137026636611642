import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Logo from "../../assets/img/medici-logo.png"

function Footer(props) {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
    const { t } = useTranslation();
    return (
        <>
            <footer className={props.footerAddclass} >
                <div className="container">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xl-4">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <Link scrollTo={scrollTop} to={"/"}>
                                            <img src={Logo} alt="FooterImg" width="100px" />
                                        </Link>
                                    </div>
                                    {/* <ul className="social-media-icons">
                                        <li>
                                            <a href="https://www.facebook.com/">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.pinterest.com/">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xl-4">
                                <div className="footer-widget">
                                    <h4>{t("Keşfet")}</h4>
                                    <ul className="footer-menu">
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>{t("Ana Sayfa")}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>{t("Hakkımızda")}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>{t("Hizmetlerimiz")}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>{t("İletişim")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xl-4">
                                <div className="footer-widget">
                                    <h4>{t("İletişim")}</h4>
                                    <div className="number">
                                        <div className="num-icon">
                                            <i className="fas fa-phone-alt" />
                                        </div>
                                        <div className="phone">
                                            <a href="tel:447832598641">+44 7832 598641</a>
                                        </div>
                                    </div>
                                    <div className="office-mail">
                                        <div className="mail-icon">
                                            <i className="far fa-envelope" />
                                        </div>
                                        <div className="email">
                                            <a href="mailto:support@mediciconsultancy.com">support@mediciconsultancy.com</a>
                                        </div>
                                    </div>
                                    <div className="address">
                                        <div className="address-icon">
                                            <i className="fas fa-map-marker-alt" />
                                        </div>
                                        <p>130-134 Victoria St, {t("Londra")} SW1E 5LA, {t("Birleşik Krallık")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-xl-12">
                                <div className="copy-txt">
                                    <span>
                                        {t("Tüm Hakları Saklıdır")} © 2023 <b>Medici Consultancy</b> - {t("Yazılım ve Danışmanlık")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;

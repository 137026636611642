import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import WOW from "wowjs";
import ServiceList from '../../ServiceList'
import { connect } from "react-redux";
import { setLanguage } from "../../store/language";
import { useTranslation } from 'react-i18next';
import TrFlag from "../../assets/img/tr.svg"
import EnFlag from "../../assets/img/en.svg"
import Logo from "../../assets/img/medici-logo.png"

function Header(props) {

    const [sidebar, setSidebar] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);


    /*---------header top Sticky event----------*/
    const handleStickyHeader = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 0);
        }
    };


    /*---------menu button event----------*/
    const handleSidbarMenu = () => {
        if (sidebar === false || sidebar === 0) {
            setSidebar(1);
        } else {
            setSidebar(false);
        }
    };

    /*---------add event scroll top----------*/
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        sidebar == true ? setSidebar(false) : setSidebar(1)
    };

    /*---------Using reducer mange the active or inactive menu----------*/
    const initialState = { activeMenu: "" };
    const [state, dispatch] = useReducer(reducer, initialState);

    function reducer(state, action) {
        switch (action.type) {
            case "homeOne":
                return { activeMenu: "homeOne" };
            case "service":
                return { activeMenu: "service" };
            case "project":
                return { activeMenu: "project" };
            case "blog":
                return { activeMenu: "blog" };
            case "page":
                return { activeMenu: "page" };
            default:
                return { activeMenu: "" };
        }
    }

    const { t, i18n } = useTranslation();

    function changeLanguages() {
        if (props.language === "en") {
            props.setLanguage("tr");
            localStorage.setItem('MediciLanguage', "tr");
            i18n.changeLanguage("tr")
        } else {
            props.setLanguage("en");
            localStorage.setItem('MediciLanguage', "en");
            i18n.changeLanguage("en")
        }
        // window.location.reload();
        return null
    }

    useEffect(() => {
        if (localStorage.getItem('MediciLanguage') === null) {
            localStorage.setItem('MediciLanguage', "tr");
            props.setLanguage("tr")
            i18n.changeLanguage("tr")
        }
        else {
            props.setLanguage(localStorage.getItem('MediciLanguage'))
            i18n.changeLanguage(localStorage.getItem('MediciLanguage'))
        }
    }, [])

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
        window.addEventListener("scroll", handleStickyHeader);
        return () => {
            window.removeEventListener("scroll", () => handleStickyHeader);
        };
    }, []);

    return (
        <>
            <header
                className={`${"header-area position_top"} ${isSticky ? "sticky" : ""}`}
                ref={ref}
            >
                <div className="site-logo">
                    <div className="logo">
                        {
                            isSticky ?
                                <Link to={"/"} onClick={scrollTop}>
                                    <img
                                        src={Logo}
                                        alt="logo-img"
                                        style={{ maxWidth: "75px" }}
                                        className="py-3"
                                    />
                                </Link>
                                :
                                <Link to={"/"} onClick={scrollTop}>
                                    <img
                                        src={Logo}
                                        alt="logo-img"
                                        style={{ maxWidth: "90px" }}
                                    />
                                </Link>
                        }
                    </div>
                </div>
                <div className="main-menu">
                    <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav"}>
                        <div className="mobile-menu-logo">
                            <Link to={"/"} onClick={scrollTop}>
                                <img
                                    src={Logo}
                                    alt="images"
                                    width="65px"
                                />
                            </Link>
                            <div className="remove" onClick={handleSidbarMenu}>
                                <i className="bi bi-plus-lg" />
                            </div>
                        </div>
                        <ul>
                            <li>
                                <NavLink
                                    to={"/"}
                                    exact
                                    onClick={scrollTop}
                                >
                                    {t("Ana Sayfa")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={`${process.env.PUBLIC_URL}/about`}
                                    onClick={scrollTop}
                                >
                                    {t("Hakkımızda")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${process.env.PUBLIC_URL}/service`} onClick={() => dispatch({ type: "service" })}>
                                    {t("Hizmetlerimiz")}
                                </NavLink>
                                <i className="bi bi-chevron-down" />
                                <ul
                                    className={
                                        state.activeMenu === "service"
                                            ? "sub-menu d-block"
                                            : "sub-menu d-none"
                                    }
                                >
                                    {
                                        ServiceList.map((item) => {
                                            return (
                                                <li key={item.id}>
                                                    <NavLink
                                                        to={{
                                                            pathname: '/service/' + `${item.link}`, state: { item }
                                                        }}
                                                        onClick={scrollTop}
                                                    >
                                                        {t(item.title)}
                                                    </NavLink>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                            <li>
                                <NavLink
                                    to={`${process.env.PUBLIC_URL}/contact`}
                                    onClick={scrollTop}
                                >
                                    {t("İletişim")}
                                </NavLink>
                            </li>
                        </ul>
                        <div className="get-qoute d-flex justify-content-center d-lg-none d-block pt-50">
                            <div className="cmn-btn">
                                <div className="line-1"></div>
                                <div className="line-2"></div>
                                <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>{t("Bize Ulaşın")}</Link>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="nav-right">
                    <a className="pe-xl-4 pe-2" style={{ cursor: "pointer" }} onClick={() => { changeLanguages() }}>
                        {props.language === "tr" ?
                            <img src={TrFlag} alt="tr" />
                            :
                            <img src={EnFlag} alt="en" />
                        }
                    </a>
                    <div className="get-qoute">
                        <div className="cmn-btn">
                            <div className="line-1" />
                            <div className="line-2" />
                            <Link
                                to={`${process.env.PUBLIC_URL}/contact`}
                                onClick={scrollTop}
                            >
                                {t("Bize Ulaşın")}
                            </Link>
                        </div>
                    </div>
                    <div className="mobile-menu">
                        <div onClick={handleSidbarMenu} className={"cross-btn"}>
                            <span className="cross-top" />
                            <span className="cross-middle" />
                            <span className="cross-bottom" />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

const mapStateToProps = state => {
    return {
        language: state.language.language,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (language) => dispatch(setLanguage(language)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

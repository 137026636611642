import React from "react";
import ServiceCard from "./ServiceCard";
import ServiceList from "../../../ServiceList"
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function ServiceCardWrap() {
    const { t } = useTranslation();
    return (
        <>
            <div className="services-grid sec-mar">
                <div className="container">
                    <div className="title-wrap">
                        <div className="sec-title">
                            <span>{t("Çözümlerimiz")}</span>
                            <h2>{t("Sunduğumuz Hizmetler")}</h2>
                        </div>
                    </div>
                    <div className="row g-4">
                        {
                            ServiceList.map((item) => {
                                return (
                                    <div className="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms" key={item.id} onClick={() => {
                                    }}>

                                        {/* Eski Component */}
                                        {/* <ServiceCard serviceNumber={item.id} image={item.img} title={item.title} details={item.desc} link={item.link} item={item} /> */}

                                        <div className='single-service'>
                                            <span>{item.id}</span>
                                            <div className="icon">
                                                <img src={`${process.env.PUBLIC_URL} ${item.img}`} alt="images" />
                                            </div>
                                            <h4>{t(item.title)}</h4>
                                            <p className="text-truncate-4">{t(item.desc)}</p>
                                            <div className="read-btn">
                                                <Link
                                                    to={{
                                                        pathname: '/service/' + `${item.link}`, state: { item }
                                                    }} >
                                                    {t("Detay")}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceCardWrap;

import React from 'react'
import { useTranslation } from 'react-i18next';

function ContactWrapper() {
    const { t } = useTranslation();
    return (
        <>
            <section className="contact-area sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-left">
                                <div className="sec-title layout2">
                                    <span>{t("İletişim")}</span>
                                    <h2>{t("Bize Ulaşın")}</h2>
                                    <p>{t("Aşağıdaki formu doldurarak bizimle iletişime geçebilirsiniz. Ekibimiz en kısa süre içinde sizlere ulaşacaktır.")}</p>
                                </div>
                                {/* <ul className="social-follow">
                                    <li><a rel="noopener noreferrer" href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a></li>
                                    <li><a rel="noopener noreferrer" href="https://www.twitter.com/"><i className="fab fa-twitter" /></a></li>
                                    <li><a rel="noopener noreferrer" href="https://www.pinterest.com/"><i className="fab fa-pinterest-p" /></a></li>
                                    <li><a rel="noopener noreferrer" href="https://www.instagram.com/"><i className="fab fa-instagram" /></a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className="getin-touch">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-7">
                                <div className="contact-form wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <h3>{t("İletİşİm Formu")}</h3>
                                    <form action="#" method="post">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" name="name" placeholder={t("İsim")} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" name="email" placeholder={t("E-posta")} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" name="subject" placeholder={t("Konu")} />
                                            </div>
                                            <div className="col-12">
                                                <textarea name="message" cols={30} rows={10} placeholder={t("Mesaj")} defaultValue={""} />
                                                <input type="submit" defaultValue="Send Message" value={t("Gönder")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 wow animate fadeInUp text-end" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="call-banner">
                                    <img src={process.env.PUBLIC_URL + "/img/call-center-2.png"} alt="images" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="informations">
                        <div className="row">
                            <div className="col-xl-4 mb-xl-0 mb-4">
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="fas fa-map-marker-alt" />
                                    </div>
                                    <div className="info">
                                        <h3>{t("Adres")}</h3>
                                        <p>130-134 Victoria St, {t("Londra")} SW1E 5LA, {t("Birleşik Krallık")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 mb-xl-0 mb-4">
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="fas fa-phone-alt" />
                                    </div>
                                    <div className="info">
                                        <h3>{t("Telefon Numarası")}</h3>
                                        <a href="tel:447832598641">+44 7832 598641</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="far fa-envelope" />
                                    </div>
                                    <div className="info">
                                        <h3>{t("E-posta Adresi")}</h3>
                                        <a href="mailto:support@mediciconsultancy.com">support@mediciconsultancy.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactWrapper
const Services =
    [
        {
            id: "01",
            img: "/img/icons/development.png",
            title: "Yazılım Geliştirme",
            desc: "Medici Consultancy, müşterilerine özelleştirilmiş yazılım çözümleri sunarak, iş süreçlerinin optimize edilmesine yardımcı olur. Müşterilere web ve mobil uygulamalar gibi farklı yazılım araçları sunulur ve yazılım geliştirme, danışmanlık ve destek hizmetleri sağlanır. Bu hizmetler, müşterilerin iş süreçlerinde verimliliği artırır ve sektörde rekabet avantajı sağlar.",
            link: "yazilim-gelistirme",
            detailImg: "/img/yazilim-gelistirme.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine özelleştirilmiş yazılım çözümleri sunarak, iş süreçlerinin optimize edilmesine yardımcı olur. Medici Consultancy, müşterilerinin iş ihtiyaçlarını anlayarak, onlara en uygun yazılım çözümlerini sunar ve yazılım geliştirme, danışmanlık ve destek hizmetleri sağlar."
                },
                {
                    p2: "Medici Consultancy, müşterilerinin farklı sektörlerdeki iş süreçlerini daha etkili hale getirmelerine yardımcı olur. Bu hizmet, müşterilerin verimliliğini artırırken, hataları azaltarak maliyetleri düşürür ve müşterilerin iş performansını optimize eder."
                },
                {
                    p3: "Müşterilere sunulan yazılım çözümleri, web uygulamaları, mobil uygulamalar ve diğer yazılım araçları içerir. Medici Consultancy, müşterilerin ihtiyaçlarına uygun yazılım çözümleri sunarak, onların iş süreçlerindeki zorlukları aşmalarına yardımcı olur ve verimliliklerini artırır."
                },
                {
                    p4: "Ayrıca, Medici Consultancy müşterilerine yazılım geliştirme, danışmanlık ve destek hizmetleri sunar. Yazılım geliştirme hizmetleri, müşterilerin ihtiyaçlarına uygun özelleştirilmiş yazılım çözümleri geliştirerek, iş süreçlerini optimize etmelerine yardımcı olur. Danışmanlık hizmetleri ise, müşterilerin iş süreçlerini analiz ederek, işlerinin verimliliğini artırmak için önerilerde bulunur. Destek hizmetleri ise, müşterilerin yazılım çözümlerini kullanırken karşılaştıkları herhangi bir sorunu çözmelerine yardımcı olur."
                },
                {
                    p5: "Medici Consultancy'nin müşterilerine sunduğu yazılım geliştirme hizmetleri, iş süreçlerinde verimliliği artırarak müşterilerin rekabet avantajını artırır ve sektörde öne çıkmalarına yardımcı olur."
                }
            ]
        },
        {
            id: "02",
            img: "/img/icons/project-management.png",
            title: "Proje Yönetimi",
            desc: "Medici Consultancy, müşterilerine özelleştirilmiş proje yönetimi çözümleri sunarak, projelerinin zamanında, bütçe dahilinde ve kaliteli bir şekilde tamamlanmasını sağlar. Bu hizmetler müşterilerin proje risklerini azaltır, proje takibini kolaylaştırır ve proje bütçelerini yönetmelerine yardımcı olur. Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin proje yönetimi konusunda uzmanlaşmalarına olanak tanır ve müşterilerin iş ihtiyaçlarını anlayarak özelleştirilmiş proje yönetimi çözümleri sunar.",
            link: "proje-yonetimi",
            detailImg: "/img/proje-yonetimi.jpeg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine proje yönetimi hizmeti sunarak, projelerinin başarılı bir şekilde tamamlanmasına yardımcı olur. Müşterilerinin ihtiyaçlarına göre özelleştirilmiş proje yönetimi çözümleri sunan Medici Consultancy, projelerin zamanında, bütçe dahilinde ve kaliteli bir şekilde tamamlanmasını sağlar."
                },
                {
                    p2: "Medici Consultancy, proje yönetimi hizmetleriyle müşterilerinin proje risklerini azaltarak, proje takibini kolaylaştırır ve projelerin başarılı bir şekilde tamamlanmasını sağlar. Proje yönetimi hizmetleri, müşterilerin ihtiyaçlarına göre özelleştirilir ve her aşamada müşterilerin proje yönetimine dahil olmasını sağlar."
                },
                {
                    p3: "Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin ihtiyaçlarına göre farklı sektörlerdeki projeler için özelleştirilir. Bu hizmetler, müşterilerin projelerinin tamamlanması için gerekli olan kaynakları belirlemelerine, proje bütçelerini yönetmelerine ve proje risklerini minimize etmelerine yardımcı olur."
                },
                {
                    p4: "Medici Consultancy, müşterilerine proje yönetimi hizmeti sunarken, müşterilerinin projelerinin tüm aşamalarında destek olur. Bu destek, proje planlamasından proje sonlandırılmasına kadar süren tüm süreçleri kapsar. Medici Consultancy'nin proje yönetimi hizmetleri, müşterilerin projelerinin başarılı bir şekilde tamamlanmasına yardımcı olur ve müşterilerin proje yönetimi konusunda uzmanlaşmalarına olanak tanır."
                },
                {
                    p5: "Sonuç olarak, Medici Consultancy'nin müşterilerine sunduğu proje yönetimi hizmetleri, müşterilerin proje yönetimindeki zorlukları aşmalarına yardımcı olur ve projelerinin başarılı bir şekilde tamamlanmasını sağlar. Medici Consultancy, müşterilerinin iş ihtiyaçlarını anlayarak, proje yönetimi çözümlerini özelleştirir ve müşterilerine proje yönetimi konusunda uzmanlık sağlar."
                }
            ]
        },
        {
            id: "03",
            img: "/img/icons/cyber-security.png",
            title: "Siber Güvenlik",
            desc: "Medici Consultancy, müşterilerine özelleştirilmiş siber güvenlik hizmetleri sunarak, dijital varlıklarının güvenliğini sağlar. Bu hizmetler arasında güvenlik duvarları, antivirüs yazılımları, zararlı yazılım koruması, veri şifreleme, izleme ve raporlama araçları yer alır. Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit ederek, ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur. Müşterilere ayrıca siber güvenlik konusunda danışmanlık, eğitim ve destek hizmetleri de sunulur. Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin iş sürekliliğini sağlar ve itibarlarını korur.",
            link: "siber-guvenlik",
            detailImg: "/img/siber-guvenlik.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine siber güvenlik hizmeti sunarak, dijital varlıklarının güvenliğini sağlar. Müşterilerin ihtiyaçlarına göre özelleştirilen siber güvenlik çözümleri sunan Medici Consultancy, müşterilerin siber saldırılardan korunmasına yardımcı olur."
                },
                {
                    p2: "Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin dijital varlıklarını korumak için farklı araçlar kullanarak saldırıları engeller. Bu araçlar arasında, güvenlik duvarları, antivirüs yazılımları, zararlı yazılım koruması, veri şifreleme, izleme ve raporlama araçları yer alır."
                },
                {
                    p3: "Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit ederek, siber saldırıların önlenmesine yardımcı olur. Bu uzmanlar, müşterilerin mevcut güvenlik sistemlerini değerlendirir ve ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur."
                },
                {
                    p4: "Medici Consultancy'nin siber güvenlik hizmetleri, müşterilerin ihtiyaçlarına göre özelleştirilir ve her aşamada müşterilerin siber güvenlik sistemlerine dahil olmasını sağlar. Müşterilere, siber güvenlik konusunda danışmanlık, eğitim ve destek hizmetleri de sunulur."
                },
                {
                    p5: "Sonuç olarak, Medici Consultancy'nin müşterilerine sunduğu siber güvenlik hizmetleri, müşterilerin dijital varlıklarının güvenliğini sağlayarak, siber saldırılardan korunmalarına yardımcı olur. Medici Consultancy'nin siber güvenlik uzmanları, müşterilerin güvenlik açıklarını tespit eder ve ihtiyaç duyulan güvenlik önlemlerinin belirlenmesine yardımcı olur. Bu hizmetler, müşterilerin iş sürekliliğini sağlar ve itibarlarını korur."
                }
            ]
        },
        {
            id: "04",
            img: "/img/icons/central.png",
            title: "Santral",
            desc: "Medici Consultancy, müşterilerine IP santral hizmeti sunarak, iletişim altyapılarını modernize etmelerine yardımcı olur. IP santral hizmeti, internet üzerinden ses ve veri iletişimini sağlayan bir teknolojidir. Medici Consultancy'nin IP santral hizmetleri, müşterilerin iş süreçlerine uygun şekilde tasarlanır, çağrı yönetimi, telefon konferansları, çağrı kaydı, mesajlaşma özellikleri gibi avantajlar sağlar. IP santral hizmeti, geleneksel telefon sistemlerine göre daha güvenli bir iletişim sağlar ve müşterilerin iş sürekliliğini korumasına yardımcı olur. Ayrıca Medici Consultancy, müşterilerine 7/24 teknik destek hizmeti sunarak, iş sürekliliği sağlamalarına yardımcı olur.",
            link: "santral",
            detailImg: "/img/santral.jpeg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine IP santral hizmeti sunarak, iletişim altyapılarını modernize etmelerine yardımcı olur. IP santral hizmeti, geleneksel telefon sistemlerinin yerini alan ve internet üzerinden ses ve veri iletişimini sağlayan bir teknolojidir."
                },
                {
                    p2: "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iş süreçlerine uygun şekilde tasarlanır. Bu hizmet sayesinde müşteriler, çağrıları yönetebilir, telefon konferansları düzenleyebilir, çağrı kayıtlarını tutabilir ve mesajlaşma özellikleriyle daha verimli bir iletişim sağlayabilirler."
                },
                {
                    p3: "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iletişim sistemlerini daha güvenli hale getirir. IP santral hizmeti, geleneksel telefon sistemlerine göre daha güvenli bir iletişim sağlar ve müşterilerin iş sürekliliğini korumasına yardımcı olur."
                },
                {
                    p4: "Medici Consultancy'nin IP santral hizmetleri, müşterilerin iletişim altyapılarını modernize ederek, iş süreçlerinde zaman ve maliyet tasarrufu sağlar. Ayrıca müşterilere 7/24 teknik destek hizmeti sunularak, olası sorunlar en kısa sürede çözülür ve müşterilerin iş sürekliliği sağlanır."
                }
            ]
        },
        {
            id: "05",
            img: "/img/icons/web-hosting.png",
            title: "Web Hosting",
            desc: "Medici Consultancy, müşterilerine web hosting hizmeti sunarak web sitelerinin kesintisiz bir şekilde yayınlanmasını sağlar. Bu hizmet, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve web sitelerinin güvenliği, performansı ve yönetimi için gerekli araçlar sağlanır. Müşterilerin web sitelerinin güvenli, hızlı ve kesintisiz bir şekilde yayınlanması için SSL sertifikaları, güvenlik araçları, hızlı altyapı ve teknolojiler kullanılır.",
            link: "web-hosting",
            detailImg: "/img/web-hosting.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine web hosting hizmeti sunarak, web sitelerinin kesintisiz bir şekilde yayınlanmasını sağlar. Web hosting hizmeti, web sitelerinin internet üzerindeki erişilebilirliğini sağlayan bir hizmettir."
                },
                {
                    p2: "Medici Consultancy'nin web hosting hizmetleri, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır. Bu hizmetler, müşterilerin web sitelerinin trafiği, depolama alanı ve diğer gereksinimleri göz önünde bulundurularak planlanır. Ayrıca Medici Consultancy, müşterilerinin web sitelerini yönetmelerine yardımcı olacak araçlar ve uygulamalar sağlar."
                },
                {
                    p3: "Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin güvenliğini sağlar. Web sitelerinin güvenliği, günümüzde büyük bir önem taşımaktadır. Bu nedenle, Medici Consultancy, müşterilerinin web sitelerinin güvenliği için gerekli olan SSL sertifikaları ve diğer güvenlik araçlarını sağlar."
                },
                {
                    p4: "Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin yüksek performanslı çalışmasını sağlar. Web sitelerinin hızlı yüklenmesi, kullanıcı deneyimi için büyük önem taşır. Bu nedenle, Medici Consultancy, müşterilerinin web sitelerinin hızlı ve kesintisiz bir şekilde yayınlanmasını sağlayacak altyapı ve teknolojileri kullanır."
                },
                {
                    p5: "Sonuç olarak, Medici Consultancy'nin web hosting hizmetleri, müşterilerin web sitelerinin güvenli, hızlı ve kesintisiz bir şekilde yayınlanmasını sağlar. Bu hizmetler, müşterilerin web sitelerini yönetmelerine yardımcı olacak araçlar, SSL sertifikaları ve diğer güvenlik araçları gibi özellikleri içerir."
                }
            ]
        },
        {
            id: "06",
            img: "/img/icons/network.png",
            title: "Network",
            desc: "Medici Consultancy, müşterilerine network hizmetleri sunarak, ağlarının performansını, güvenliğini ve yönetimini sağlamakta ve işletmelerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamaktadır. Hizmetler, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve ağlarının güvenli, hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli teknolojiler kullanılır. Ağ güvenliği, işletmeler için kritik bir konudur ve Medici Consultancy, müşterilerinin ağlarını korumak için gerekli olan güvenlik araçlarını sağlar.",
            link: "network",
            detailImg: "/img/network.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine network hizmeti sunarak, işletmelerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamalarına yardımcı olur. Network hizmetleri, işletmelerin iş süreçlerini optimize etmeleri ve verimliliklerini artırmalarına yardımcı olan kritik bir bileşendir."
                },
                {
                    p2: "Medici Consultancy'nin network hizmetleri, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır. Bu hizmetler, müşterilerin ağlarındaki performans, güvenlik ve diğer gereksinimleri göz önünde bulundurularak planlanır. Ayrıca, Medici Consultancy, müşterilerinin network altyapılarının yönetimini de üstlenir."
                },
                {
                    p3: "Medici Consultancy'nin network hizmetleri, müşterilerin ağlarının güvenliğini sağlar. Ağ güvenliği, işletmeler için kritik bir konudur. Bu nedenle, Medici Consultancy, müşterilerinin ağlarını korumak için gerekli olan güvenlik araçlarını sağlar."
                },
                {
                    p4: "Medici Consultancy'nin network hizmetleri, müşterilerin ağlarının performansını artırır. Ağ performansı, işletmelerin verimliliği ve iş süreçlerinin optimize edilmesi için önemlidir. Medici Consultancy, müşterilerin ağlarının hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli altyapı ve teknolojileri kullanır."
                },
                {
                    p5: "Sonuç olarak, Medici Consultancy'nin network hizmetleri, müşterilerin iletişim ve bilgi paylaşımı ihtiyaçlarını karşılamalarına yardımcı olur. Bu hizmetler, müşterilerin ihtiyaçlarına uygun şekilde tasarlanır ve ağların performansı, güvenliği ve yönetimi için gerekli araçları sağlar. Medici Consultancy, müşterilerin ağlarının güvenli, hızlı ve kesintisiz bir şekilde çalışmasını sağlamak için gerekli teknolojileri kullanır."
                }
            ]
        },
        {
            id: "07",
            img: "/img/icons/crm.png",
            title: "CRM",
            desc: "Medici Consultancy, müşterilerine özel CRM (Müşteri İlişkileri Yönetimi) hizmetleri sunmaktadır. Bu hizmetler işletmelerin müşteri verilerini yönetmelerini, müşteri etkileşimlerini analiz etmelerini ve müşteri memnuniyetini artırmalarını sağlar. Medici Consultancy, müşterilerin mevcut müşteri verilerini analiz ederek özel çözümler sunar ve işletmelerin müşteri ilişkileri yönetimi süreçlerini otomatikleştirmelerine yardımcı olur. Bu hizmetler müşterilerin satış süreçlerini optimize etmelerine ve gelirlerini artırmalarına da katkı sağlar. Medici Consultancy, müşteri odaklı ve esnek çözümler sunarak işletmelerin hedeflerine ulaşmalarına yardımcı olur.",
            link: "crm",
            detailImg: "/img/crm.png",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine CRM (Müşteri İlişkileri Yönetimi) hizmetleri sunarak işletmelerin müşterileriyle daha iyi etkileşim kurmalarına ve müşteri memnuniyetini artırmalarına yardımcı olmaktadır. CRM hizmetleri, müşterilerin ihtiyaçlarına göre tasarlanır ve işletmelerin müşteri verilerini yönetmelerini, müşteri bilgilerini takip etmelerini ve müşteri etkileşimlerini analiz etmelerini sağlar."
                },
                {
                    p2: "Medici Consultancy, müşterilerine CRM hizmetleri sunarken, müşterilerinin mevcut müşteri verilerini analiz ederek, müşterilerine özel çözümler sunar. Bu sayede müşteriler, işletmelerinde müşteri ilişkileri yönetimi süreçlerini otomatikleştirebilir, müşteri verilerini güvenli bir şekilde saklayabilir ve müşteri etkileşimlerini daha verimli hale getirebilirler."
                },
                {
                    p3: "Medici Consultancy'nin CRM hizmetleri, işletmelerin müşteri memnuniyetini artırmalarına yardımcı olmanın yanı sıra, müşterilerin satış süreçlerini optimize etmelerine ve gelirlerini artırmalarına da katkı sağlar. Bu hizmetler, müşterilerin ihtiyaçlarına uygun olarak tasarlanır ve işletmelerin müşterileriyle daha iyi bir ilişki kurmalarına ve pazarlama stratejilerini geliştirmelerine yardımcı olur. Medici Consultancy, müşterilerinin işletme hedeflerine ulaşmalarına yardımcı olmak için müşteri odaklı ve esnek çözümler sunar."
                }
            ]
        },
        {
            id: "08",
            img: "/img/icons/devops.png",
            title: "DevOps",
            desc: "Medici Consultancy, müşterilerine DevOps hizmetleri sunarak yazılım geliştirme ve operasyonel süreçlerin entegrasyonunu sağlar. Bu hizmetler, müşterilerin yazılım geliştirme süreçlerini optimize eder, daha hızlı ve güvenli yazılım dağıtımı yapmalarına olanak tanır. Medici Consultancy, müşterilerine özel DevOps stratejileri sunarak işletmelerin ihtiyaçlarına ve hedeflerine uygun çözümler geliştirir.",
            link: "devops",
            detailImg: "/img/devops.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine DevOps hizmetleri sunarak, işletmelerin yazılım geliştirme ve operasyonel süreçlerini entegre etmelerine yardımcı olur. DevOps, geliştirme ve operasyonel ekiplerin işbirliği yaparak hızlı, güvenilir ve sürekli yazılım dağıtımı sağlamalarına olanak tanıyan bir kültür ve yöntemler bütünüdür."
                },
                {
                    p2: "Medici Consultancy, müşterilerine bu süreçte yardımcı olmak için, geliştirme, test ve operasyonel süreçlerin tamamını entegre eden DevOps hizmetleri sunar. Müşterilerin yazılım geliştirme sürecinde zaman, bütçe ve kaynak tasarrufu sağlamalarına yardımcı olur. Ayrıca, müşterilerin yazılım dağıtım süreçlerini hızlandırmalarına ve daha yüksek kaliteli yazılım teslim etmelerine olanak tanır."
                },
                {
                    p3: "Medici Consultancy, müşterilerine özel DevOps stratejileri sunarak, işletmelerin hedeflerine ve ihtiyaçlarına uygun çözümler geliştirir. Bu çözümler, işletmelerin yazılım geliştirme süreçlerini optimize ederek, daha hızlı ve daha güvenli yazılım dağıtımı yapmalarına olanak sağlar."
                },
                {
                    p4: "Sonuç olarak, Medici Consultancy'nin DevOps hizmetleri müşterilerin yazılım geliştirme ve operasyonel süreçlerini entegre etmelerine yardımcı olur. Bu hizmetler, müşterilerin yazılım geliştirme süreçlerini hızlandırmalarına, daha yüksek kaliteli yazılım teslim etmelerine ve maliyetlerini azaltmalarına olanak tanır. Medici Consultancy, müşterilerine özel çözümler sunarak, işletmelerin hedeflerine ulaşmalarına yardımcı olur."
                }
            ]
        },
        {
            id: "09",
            img: "/img/icons/trading-platforms.png",
            title: "Trading Platforms",
            desc: "Medici Consultancy, müşterilerine yüksek performanslı ve güvenli bir ticaret platformu sunarak, finansal piyasalarda rekabet avantajı elde etmelerini sağlıyor. Trading Platforms hizmetleri kapsamında, müşteri ihtiyaçlarına özel özelleştirilebilir yazılımlar geliştiriyor, platformların bakım ve güncelleme işlemlerini gerçekleştiriyor, mevcut sistemlerin iyileştirilmesine yardımcı oluyor ve müşterilerine teknik destek hizmeti sunuyor. Bu sayede müşteriler, kendi işlerine odaklanırken, Medici Consultancy tarafından sunulan hizmetler sayesinde finansal piyasalarda başarılı olabiliyorlar.",
            link: "trading-platforms",
            detailImg: "/img/trading-platforms.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine Trading Platforms hizmeti sunarak finansal kurumlar ve yatırımcıların ihtiyaçlarına uygun çözümler sunar. Bu hizmetler, müşterilerin borsa ve finans piyasalarındaki ticaret faaliyetlerini optimize eder, daha hızlı ve güvenli işlemler yapmalarına olanak tanır. Medici Consultancy, müşterilerine özel Trading Platforms stratejileri sunarak işletmelerin ihtiyaçlarına ve hedeflerine uygun çözümler geliştirir."
                },
                {
                    p2: "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin ticaret stratejilerini otomatikleştirmelerine olanak tanıyan algoritmik işlem platformları da dahil olmak üzere bir dizi farklı hizmeti kapsar. Bu platformlar, müşterilerin yatırımlarını daha verimli bir şekilde yönetmelerine ve daha akıllıca yatırım kararları almalarına yardımcı olur."
                },
                {
                    p3: "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin özelleştirilmiş ticaret stratejileri oluşturmasına ve bu stratejileri daha etkili bir şekilde uygulamalarına olanak tanır. Müşteriler, hızlı ve güvenilir veri akışı sayesinde piyasaların anlık durumunu takip edebilir ve daha iyi yatırım kararları alabilirler."
                },
                {
                    p4: "Medici Consultancy'nin Trading Platforms hizmetleri, müşterilerin yatırım faaliyetlerinin hızını ve etkinliğini artırmalarına yardımcı olurken, aynı zamanda güvenliği de sağlar. Medici Consultancy, müşterilerinin güvenliğini önemseyerek, platformlarına üst düzey güvenlik önlemleri ekler. Böylece müşterilerinin finansal verileri ve yatırımları her zaman güvence altında olur."
                }
            ]
        },
        {
            id: "10",
            img: "/img/icons/cloud-services.png",
            title: "Cloud Services",
            desc: "Medici Consultancy, müşterilerine bulut hizmeti sunarak işletmelerin daha esnek ve ölçeklenebilir bir altyapıya sahip olmalarını sağlıyor. Bulut hizmetleri kapsamında özelleştirilebilir bir altyapı, veri yedekleme ve depolama çözümleri, güvenlik önlemleri ve teknik destek hizmeti sunuluyor. Bu hizmetler işletmelerin kaynaklarını verimli bir şekilde kullanmalarına, ölçeklenebilirliği artırmalarına ve maliyetleri optimize etmelerine yardımcı oluyor.",
            link: "cloud-services",
            detailImg: "/img/cloud-services.jpg",
            paragraph: [
                {
                    p1: "Medici Consultancy, müşterilerine güvenli, hızlı ve yüksek performanslı bir bulut hizmeti sunarak, işletmelerin daha esnek ve ölçeklenebilir bir altyapıya sahip olmalarını sağlıyor. Cloud Services hizmetleri kapsamında, müşteri ihtiyaçlarına özel özelleştirilebilir bir bulut altyapısı sunuyor, veri yedekleme ve depolama çözümleri sağlıyor, uygulamaların ve verilerin güvenliğini korumak için gerekli önlemleri alıyor ve müşterilerine teknik destek hizmeti sunuyor."
                },
                {
                    p2: "Medici Consultancy'nin sunduğu bulut hizmeti, işletmelerin kaynaklarını verimli bir şekilde kullanmalarına ve işlerini geliştirmelerine yardımcı oluyor. Bulut hizmetleri, esnek bir ödeme modeli sunarak işletmelerin maliyetlerini optimize etmelerine de yardımcı oluyor. Ayrıca, işletmelerin ölçeklenebilirliği artırarak, büyüme hedeflerine daha hızlı ulaşmalarına yardımcı oluyor."
                },
                {
                    p3: "Medici Consultancy'nin Cloud Services hizmeti, işletmelerin ihtiyaçlarına özel olarak tasarlanmış bir bulut altyapısı sunarak, müşterilerin verimliliğini artırmasına ve rekabet avantajı elde etmesine yardımcı oluyor."
                }
            ]
        }
    ]

export default Services;
import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ServiceCardWrap from "./ServiceCardWrap";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function Service() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("Medici Consultancy | Hizmetlerimiz")}</title>
            </Helmet>
            <div className="creasoft-wrap">
                <Breadcrumb pageName={t("Hizmetlerimiz")} pageTitle={t("Hizmetlerimiz")} />
                <ServiceCardWrap />
                <SubscribArea />
            </div>
        </>
    );
}

export default Service;

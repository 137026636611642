import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function HeroArea(props) {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

    const { t } = useTranslation();

    return (
        <>
            <section className="hero-area">
                <div className="ken-burns-slideshow">
                    <img className='position-relative' src={process.env.PUBLIC_URL + "/img/hero-banner.jpeg"} alt="HeroBanner" />
                    <div className='banner-overlay'></div>
                </div>
                {/* <div className="verticale-social">
                    <ul className="vertical-media">
                        <li><a href="https://www.facebook.com/">Facebook</a></li>
                        <li><a href="https://www.instagram.com/">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/">Linkedin</a></li>
                    </ul>
                </div> */}
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="hero-content">
                                    {/* <h1>Creative &amp; Minimal<span>It Agency.</span></h1> */}
                                    <h1>{t("Uzman Danışmanlık")} <span>{t("Hİzmetlerİ")}</span></h1>
                                    <p>{t("Medici Consultancy olarak, yazılım ve danışmanlık hizmetleri sunmaktayız ve müşterilerimize özelleştirilmiş çözümler sunarak kurumsal hedeflerine ulaşmalarına ve karlılıklarını artırmalarına yardımcı oluyoruz. Medici Consultancy olarak, her zaman en iyi hizmeti sunmak için çaba gösteriyoruz.")}</p>
                                    <div className="buttons">
                                        <div className="cmn-btn layout-two">
                                            <div className="line-1" />
                                            <div className="line-2" />
                                            <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>{t("Hakkımızda")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroArea